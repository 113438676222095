.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-image: linear-gradient(to right, #DCDCDC, white);
  text-align: justify;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: calc(50px + 3vmin);
  pointer-events: none;
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.margin{
  margin: 3vh;
}

.narrowMargin{
  margin: 1vh;
}

.marginTop{
  margin-top: 5vh;
}

.formControl{
  width: 125px;
}

.table{
  max-width: 500px;
}

.cookieBar{
  max-width: 90%
}

.formControlLong{
  width: 300px;
}

.flexChild{
  flex: 1;
}

.flexChildWide{
  flex: 2;
}

.center{
  align-items: center;
}

.justify{
  justify-content: center
}

.full{
  justify-content: space-evenly;
  width: 100%;
}

.footer{
  background-color: grey;
  height: 10vsh;
  padding: 5vh;
  color: white
}

.section {
  min-height: 85vh;
}

.subsection {
  min-height: 75vh;
  min-width: 250px;
}

.App-link {
  text-decoration: none;
}

.link{
  color: white;
  text-decoration: none;
}

.App-phone{
  width: 300px;
  height: 600px;
}

.google-play{
  height: 75px;
  width: 225px;
}

.app-store{
  height: 75px;
  width: 250px;
}

.lang-picker{
  width: 75px;
  height: 68px;
  margin: 15px
}

.list-item{
  height:40px;
}

.paragraph{
  width: calc(200px + 25vw);
  text-align: justify;
}

.icon{
  margin-right: 15px;
}

.privacy-container{
  width: 80%;
}

.subheader{
  font-size: calc(12px + 1vmin);
}

.paragraph-header{
  font-size: calc(12px + 2vmin);
}

.site-header{
  font-size: calc(12px + 3vmin);
}

/* 
@keyframes App-logo-spin {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
} */

@keyframes fade-in {
  from {opacity: 0; transform: scale(.7,.7)}
  to {opacity: 1;}
}
.fade-in-element {
  animation: fade-in 2.4s;
}


@keyframes fade-out {
  from {opacity: 1; }
  to {opacity: 0; transform: scale(.7,.7)}
}
.hidden {
  opacity: 0;
  animation: fade-out 2.4s
}




